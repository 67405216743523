import React from "react";

const Post = ({ title, body }) => {
  return (
    <div>
      <span>{title}</span>
      <br />
      <span>{body}</span>
    </div>
  );
};

export default Post;
