import { Fragment } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import HomePage from "./pages/home";
import BlogPage from "./pages/blog";
import AboutPage from "./pages/about";
import ContactPage from "./pages/contact";

import NavBar from "./components/nav-bar";

function App() {
  return (
    <BrowserRouter>
      <div className="app__content">
        <span className="material-icons">monitor</span>
        <span>maroy.dev</span>
      </div>
      <hr />
      <NavBar />
      <div className="app__page-content">
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/blog">
            <BlogPage />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
