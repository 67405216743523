import React, { useState, useEffect } from "react";

import Post from "./post";

const PostList = () => {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    fetch("https://maroy-dev-default-rtdb.firebaseio.com/posts.json")
      .then((r) => r.json())
      .then((data) => {
        const posts = [];
        for (let key in data) {
          posts.push({
            ...data[key],
            key: key,
          });
        }
        setPosts(posts);
      });
  }, []);

  return (
    <div>
      {!posts && "Loading..."}
      {posts &&
        posts.map((post) => (
          <Post key={post.key} title={post.title} body={post.body} />
        ))}
    </div>
  );
};

export default PostList;
