import React from "react";

import PostList from "../components/post-list";

const BlogPage = () => {
  return (
    <div>
      Blog
      <br />
      <PostList />
    </div>
  );
};

export default BlogPage;
